import { Observable } from 'rxjs';

import { FirmwarePackage, PropertyPanelDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';

export abstract class DevicePropertyPanelApiService {
	/**
	 * get device by id
	 */
	public abstract getDevice$(deviceId: string): Observable<PropertyPanelDevice>;

	/*
	 * forget/unsubscribe to a device
	 */
	public abstract forgetDevice(deviceId: string): void;

	/**
	 * Set mute for a device.
	 */
	public abstract setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>>;

	/**
	 * Set device name.
	 */
	public abstract setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>>;

	/**
	 * Set device identifying state.
	 */
	public abstract setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>>;

	/**
	 * Reboot device
	 */
	public abstract rebootDevice(deviceId: string): Observable<UpdateResponse<void, string>>;

	/**
	 * Request a device firmware update
	 */
	public abstract updateFirmware(
		deviceId: string,
		firmwarePkg: FirmwarePackage
	): Observable<UpdateResponse<void, string>>;

	/**
	 * Associate a tag with one or more devices
	 */
	public abstract associateTag(deviceId: string[], tag: string): Observable<UpdateResponse<void, string>>;

	/**
	 * Dissociate a tag with one or more devices
	 */
	public abstract dissociateTag(deviceId: string[], tag: string): Observable<UpdateResponse<void, string>>;
}
